var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('vs-table',{attrs:{"search":"","stripe":"","border":"","description":"","sst":true,"data":_vm.table.data,"max-items":_vm.table.length,"total":_vm.table.total},on:{"search":_vm.handleSearch,"change-page":_vm.handleChangePage,"sort":_vm.handleSort},scopedSlots:_vm._u([{key:"default",fn:function({ data }){return _vm._l((data),function(tr,indextr){return _c('vs-tr',{key:indextr,attrs:{"data":tr}},_vm._l((_vm.table.heads),function(head,index){return _c('vs-td',{key:index,attrs:{"data":data[indextr][head.field]}},[(
            head.field == 'file_name' && data[indextr]['file_name'] != ''
          )?_c('a',{attrs:{"target":"_blank"},on:{"click":function($event){return _vm.downloadFile(data[indextr]['file_name'])}}},[_vm._v(" "+_vm._s(data[indextr]["file_name"])+" ")]):(
            head.field == 'created_by' && data[indextr]['created_by'] != ''
          )?_c('span',[_vm._v(" "+_vm._s(data[indextr]["created_by"])+" ")]):(
            head.field == 'created_at'
          )?_c('span',[_vm._v(" "+_vm._s(_vm.dateFormat(data[indextr][head.field]))+" ")]):_c('span',[_vm._v(_vm._s(data[indextr][head.field]))])])}),1)})}}])},[_c('template',{slot:"header"},[_c('vs-dropdown',{staticClass:"cursor-pointer",attrs:{"vs-trigger-click":""}},[_c('div',{staticClass:"p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"},[_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(this.table.start)+" - "+_vm._s(this.table.end)+" of "+_vm._s(this.table.total))]),_c('feather-icon',{attrs:{"icon":"ChevronDownIcon","svgClasses":"h-4 w-4"}})],1),_c('vs-dropdown-menu',_vm._l((_vm.table.limits),function(item){return _c('vs-dropdown-item',{key:item,on:{"click":function($event){return _vm.handleChangelength(item)}}},[_c('span',[_vm._v(_vm._s(item))])])}),1)],1)],1),_c('template',{slot:"thead"},_vm._l((this.table.heads),function(head,index){return _c('vs-th',{key:index,attrs:{"sort-key":head.field}},[_vm._v(_vm._s(head.title))])}),1)],2),_c('vs-pagination',{staticStyle:{"padding-top":"5px"},attrs:{"total":_vm.table.totalPage},model:{value:(_vm.setPage),callback:function ($$v) {_vm.setPage=$$v},expression:"setPage"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }