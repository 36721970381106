<template>
  <div>
    <vs-table
      search
      stripe
      border
      description
      :sst="true"
      :data="table.data"
      :max-items="table.length"
      :total="table.total"
      @search="handleSearch"
      @change-page="handleChangePage"
      @sort="handleSort"
    >
      <template slot="header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div
            class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2">
              {{ this.table.start }} - {{ this.table.end }} of
              {{ this.table.total }}
            </span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item
              v-for="item in table.limits"
              :key="item"
              @click="handleChangelength(item)"
            >
              <span> {{ item }} </span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
        <div class="btn-group ml-5">
          <vs-button
            size="small"
            color="success"
            type="border"
            @click="handleExport('excel')"
            >Excel</vs-button
          >
        </div>
      </template>
      <template slot="thead">
        <vs-th sort-key="branch_code">Branch Code</vs-th
        ><vs-th sort-key="branch">Branch</vs-th
        ><vs-th sort-key="sku_code">SKU Code</vs-th
        ><vs-th sort-key="sku_name">SKU Deskripsi</vs-th
        ><vs-th sort-key="batch_number">Batch Number</vs-th
        ><vs-th sort-key="trans_time">Trans Date</vs-th
        ><vs-th sort-key="posting_date">Posting Date</vs-th
        ><vs-th sort-key="document_date">Document Date</vs-th
        ><vs-th sort-key="movement_type_description"
          >Movement Type Description</vs-th
        ><vs-th sort-key="trx_ref_code">Trx. Ref. Code</vs-th
        ><vs-th sort-key="doc_ref_code">Doc. Ref. Code</vs-th
        ><vs-th sort-key="doc_ref_snd">Doc. Ref. SND</vs-th
        ><vs-th sort-key="storage_location_code">Storage Location Code</vs-th
        ><vs-th sort-key="storage_location">Storage Location</vs-th
        ><vs-th sort-key="best_before_date">Best Before Date</vs-th
        ><vs-th sort-key="uom">UoM</vs-th
        ><vs-th sort-key="beginning_qty">Beginning Qty</vs-th
        ><vs-th sort-key="movement_qty">Movement Qty</vs-th
        ><vs-th sort-key="ending_qty">Ending Qty</vs-th
        ><vs-th sort-key="inventory_value_map">Inventory Value (Map)</vs-th
        ><vs-th sort-key="total_inventory_movement_value"
          >Total Inventory Movement Value</vs-th
        ><vs-th sort-key="total_ending_inventory_value"
          >Total Ending Inventory Value</vs-th
        >
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].branch_code">{{
            data[indextr].branch_code
          }}</vs-td
          ><vs-td :data="data[indextr].branch">{{ data[indextr].branch }}</vs-td
          ><vs-td :data="data[indextr].sku_code">{{
            data[indextr].sku_code
          }}</vs-td
          ><vs-td :data="data[indextr].sku_name">{{
            data[indextr].sku_name
          }}</vs-td
          ><vs-td :data="data[indextr].batch_number">{{
            data[indextr].batch_number
          }}</vs-td
          ><vs-td :data="data[indextr].trans_time">{{
            data[indextr].trans_time
          }}</vs-td
          ><vs-td :data="data[indextr].posting_date">{{
            data[indextr].posting_date
          }}</vs-td
          ><vs-td :data="data[indextr].document_date">{{
            data[indextr].document_date
          }}</vs-td
          ><vs-td :data="data[indextr].movement_type_description">{{
            data[indextr].movement_type_description
          }}</vs-td
          ><vs-td :data="data[indextr].trx_ref_code">{{
            data[indextr].trx_ref_code
          }}</vs-td
          ><vs-td :data="data[indextr].doc_ref_code">{{
            data[indextr].doc_ref_code
          }}</vs-td
          ><vs-td :data="data[indextr].doc_ref_snd">{{
            data[indextr].doc_ref_snd
          }}</vs-td
          ><vs-td :data="data[indextr].storage_location_code">{{
            data[indextr].storage_location_code
          }}</vs-td
          ><vs-td :data="data[indextr].storage_location">{{
            data[indextr].storage_location
          }}</vs-td
          ><vs-td :data="data[indextr].best_before_date">{{
            data[indextr].best_before_date
          }}</vs-td
          ><vs-td :data="data[indextr].uom">{{ data[indextr].uom }}</vs-td
          ><vs-td :data="data[indextr].beginning_qty">{{
            formatPrice(data[indextr].beginning_qty)
          }}</vs-td
          ><vs-td :data="data[indextr].movement_qty">{{
            formatPrice(data[indextr].movement_qty)
          }}</vs-td
          ><vs-td :data="data[indextr].ending_qty">{{
            formatPrice(data[indextr].ending_qty)
          }}</vs-td
          ><vs-td :data="data[indextr].inventory_value_map">{{
            formatPrice(data[indextr].inventory_value_map)
          }}</vs-td
          ><vs-td :data="data[indextr].total_inventory_movement_value">{{
            formatPrice(data[indextr].total_inventory_movement_value)
          }}</vs-td
          ><vs-td :data="data[indextr].total_ending_inventory_value">{{
            formatPrice(data[indextr].total_ending_inventory_value)
          }}</vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination
      style="padding-top: 5px"
      :total="table.totalPage"
      v-model="setPage"
    />
  </div>
</template>
<script>
import moment from 'moment';
import { mapActions, mapState } from 'vuex';

export default {
  components: {},
  props: {
    baseUrl: {
      type: String,
    },
    startPostingDate: {
      type: Date,
    },
    endPostingDate: {
      type: Date,
    },
    startDocDate: {
      type: Date,
    },
    endDocDate: {
      type: Date,
    },
    startDueDate: {
      type: Date,
    },
    endDueDate: {
      type: Date,
    },
    openKeyDate: {
      type: Date,
    },
    territoryIDs: {
      type: Array,
    },
    territoryNames: {
      type: Array,
    },
    draw: {
      type: Number,
    },
  },
  computed: {
    ...mapState({
      tableData: (state) => state.reportSTMovement.tableData,
      total: (state) => state.reportSTMovement.total,
      totalPerPage: (state) => state.reportSTMovement.totalPerPage,
      totalPage: (state) => state.reportSTMovement.totalPage,
      totalSearch: (state) => state.reportSTMovement.totalSearch,
    }),
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
  data() {
    return {
      deleteId: null,
      table: this.tableDefaultState(),
    };
  },
  methods: {
    ...mapActions({
      getTableData: 'reportSTMovement/getSTMovementReport',
      generateSTMovementReport: 'reportSTMovement/generateSTMovementReport',
    }),
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: '',
        order: 'branch_code',
        sort: 'desc',
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, 'All'],
        start: 1,
        end: 0,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == 'All' ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    getData() {
      this.$vs.loading();
      this.getTableData({
        length: this.table.length,
        page: this.table.page,
        search: this.table.search,
        order: this.table.order,
        sort: this.table.sort,
      }).then((resp) => {
        this.$vs.loading.close();
        this.table.data = resp.records;
      });
    },
    setStartEnd() {
      let valStart =
        this.tableData.length * this.table.page - this.tableData.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.tableData.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (this.totalSearch < this.table.total && this.table.search != '') {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    dateFormat(val) {
      return moment(val).format('DD MMM YYYY');
    },
    handleExport(file) {
      this.$vs.loading();
      this.generateSTMovementReport({
        search: this.table.search,
        length: this.table.length,
        page: this.table.page,
        order: this.table.order,
        sort: this.table.sort,
      }).then((resp) => {
        if (resp.status == 'OK') {
          this.$vs.notify({
            color: 'danger',
            title: 'Error',
            text: resp.message,
            position: 'top-right',
            iconPack: 'feather',
            icon: 'icon-x-circle',
          });
        } else {
          this.$vs.notify({
            color: 'success',
            title: 'Processing',
            text: resp.message,
            position: 'top-right',
            iconPack: 'feather',
            icon: 'icon-x-circle',
          });
        }
      }).finally(() => {
        this.$vs.loading.close();
      });
    },
    formatPrice(val) {
      if (isNaN(val)) {
        val = 0;
      }
      val = (val / 1).toFixed(2).replace(',', '.');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    format(head, value) {
      if (typeof head['format'] === 'function') {
        var f = head['format'];
        return f(value);
      }
      return value;
    },
  },
  watch: {
    // detail() {
    //   this.getData();
    // },
    // startPostingDate() {
    //   this.getData();
    // },
    // endPostingDate() {
    //   this.getData();
    // },
    // startDocDate() {
    //   this.getData();
    // },
    // endDocDate() {
    //   this.getData();
    // },
    // startDueDate() {
    //   this.getData();
    // },
    // endDueDate() {
    //   this.getData();
    // },
    // openKeyDate() {
    //   this.getData();
    // },
    // territoryID() {
    //   this.getData();
    // },
    draw() {
      this.getData();
    },
    tableData(val) {
      this.table.data = val;
    },
    total(val) {
      this.table.total = val;
    },
    totalPerPage(val) {
      this.table.totalPerPage = val;
    },
    totalPage(val) {
      this.table.totalPage = val;
    },
    totalSearch(val) {
      this.table.totalSearch = val;
    },
    'table.data'() {
      this.setStartEnd();
    },
  },
  // mounted() {
  //   this.getData();
  // },
};
</script>
